import MainService from './MainService'
class SiteService extends MainService {

    constructor() {
        super()
        this.api_name = "EnergyWebSiteApi"
    }

    cookieRegister = async(data) => {
        return this.doRequest("post", `/website/cookie-register/`, data)
    }

}

export default new SiteService();
