import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class MinumModule extends VuexModule{
  show_loading = false;
  onboarding_title = "";
  onboarding_step = "";
  accept_offer = false;
  signed_document = false;
  onboarding_next = false;
  onboarding_back = false;
  onboarding_next_enabled = false;
  onboarding_back_enabled = false;

  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get getShowLoading() {
    return this.show_loading;
  }

  get getOnboardingTitle() {
    return this.onboarding_title;
  }

  get getOnboardingNext() {
    return this.onboarding_next;
  }

  get getOnboardingBack() {
    return this.onboarding_back;
  }

  get getOnboardingNextEnabled() {
    return this.onboarding_next_enabled;
  }

  get getOnboardingBackEnabled() {
    return this.onboarding_back_enabled;
  }

  get getOnboardingStep() {
    return this.onboarding_step;
  }

  get getAccepOffer() {
    return this.accept_offer
  }

  get getSignedDocument() {
    return this.signed_document
  }

  @Mutation
  [Mutations.SET_SHOW_LOADING_MUTATION](show) {
    this.show_loading = show;
  }

  @Action
  [Actions.SET_SHOW_LOADING](show) {
    this.context.commit(Mutations.SET_SHOW_LOADING_MUTATION, show);
  }

  @Mutation
  [Mutations.SET_ONBOARDING_TITLE_MUTATION](title) {
    this.onboarding_title = title;
  }

  @Action
  [Actions.SET_ONBOARDING_TITLE](title) {
    this.context.commit(Mutations.SET_ONBOARDING_TITLE_MUTATION, title);
  }


  @Mutation
  [Mutations.SET_ONBOARDING_STEP_MUTATION](onboarding_step) {
    this.onboarding_step = onboarding_step;
  }

  @Action
  [Actions.SET_ONBOARDING_STEP](onboarding_step) {
    this.context.commit(Mutations.SET_ONBOARDING_STEP_MUTATION, onboarding_step);
  }


  @Mutation
  [Mutations.SET_ACCEPT_OFFER_MUTATION](accept_offer) {
    this.accept_offer = accept_offer;
  }

  @Action
  [Actions.SET_ACCEPT_OFFER](accept_offer) {
    this.context.commit(Mutations.SET_ACCEPT_OFFER_MUTATION, accept_offer);
  }


  @Mutation
  [Mutations.SET_SIGNED_DOCUMENT_MUTATION](signed_document) {
    this.signed_document = signed_document;
  }

  @Action
  [Actions.SET_SIGNED_DOCUMENT](signed_document) {
    this.context.commit(Mutations.SET_SIGNED_DOCUMENT_MUTATION, signed_document);
  }
  
  @Mutation
  [Mutations.SET_ONBOARDING_NEXT_MUTATION](onboarding_next) {
    this.onboarding_next = onboarding_next;
  }

  @Action
  [Actions.SET_ONBOARDING_NEXT](onboarding_next) {
    this.context.commit(Mutations.SET_ONBOARDING_NEXT_MUTATION, onboarding_next);
  }

  @Mutation
  [Mutations.SET_ONBOARDING_BACK_MUTATION](onboarding_back) {
    this.onboarding_back = onboarding_back;
  }

  @Action
  [Actions.SET_ONBOARDING_BACK](onboarding_back) {
    this.context.commit(Mutations.SET_ONBOARDING_BACK_MUTATION, onboarding_back);
  }

  @Mutation
  [Mutations.SET_ONBOARDING_NEXT_ENABLED_MUTATION](enabled) {
    this.onboarding_next_enabled = enabled;
  }

  @Action
  [Actions.SET_ONBOARDING_NEXT_ENABLED](enabled) {
    this.context.commit(Mutations.SET_ONBOARDING_NEXT_ENABLED_MUTATION, enabled);
  }

  @Mutation
  [Mutations.SET_ONBOARDING_BACK_ENABLED_MUTATION](enabled) {
    this.onboarding_back_enabled = enabled;
  }

  @Action
  [Actions.SET_ONBOARDING_BACK_ENABLED](enabled) {
    this.context.commit(Mutations.SET_ONBOARDING_BACK_ENABLED_MUTATION, enabled);
  }
}

