import MainService from './MainService'
import axios from "axios";
import LocalStorageService from "./LocalStorageService"

const UFs = [
    { code: 12, initials: 'AC', state: 'Acre' },
    { code: 27, initials: 'AL', state: 'Alagoas' },
    { code: 13, initials: 'AM', state: 'Amazonas' },
    { code: 16, initials: 'AP', state: 'Amapá' },
    { code: 29, initials: 'BA', state: 'Bahia' },
    { code: 23, initials: 'CE', state: 'Ceará' },
    { code: 53, initials: 'DF', state: 'Distrito Federal' },
    { code: 32, initials: 'ES', state: 'Espírito Santo' },
    { code: 52, initials: 'GO', state: 'Goiás' },
    { code: 21, initials: 'MA', state: 'Maranhão' },
    { code: 31, initials: 'MG', state: 'Minas Gerais' },
    { code: 50, initials: 'MS', state: 'Mato Grosso do Sul' },
    { code: 51, initials: 'MT', state: 'Mato Grosso' },
    { code: 15, initials: 'PA', state: 'Pará' },
    { code: 25, initials: 'PB', state: 'Paraíba' },
    { code: 26, initials: 'PE', state: 'Pernambuco' },
    { code: 22, initials: 'PI', state: 'Piauí' },
    { code: 41, initials: 'PR', state: 'Paraná' },
    { code: 33, initials: 'RJ', state: 'Rio de Janeiro' },
    { code: 24, initials: 'RN', state: 'Rio Grande do Norte' },
    { code: 11, initials: 'RO', state: 'Rondônia' },
    { code: 14, initials: 'RR', state: 'Roraima' },
    { code: 43, initials: 'RS', state: 'Rio Grande do Sul' },
    { code: 42, initials: 'SC', state: 'Santa Catarina' },
    { code: 28, initials: 'SE', state: 'Sergipe' },
    { code: 35, initials: 'SP', state: 'São Paulo' },
    { code: 17, initials: 'TO', state: 'Tocantins' }
]
class UtilsService extends MainService {

    constructor() {
        super()
        this.api_name = "EnergyWebSiteApi"
    }

    setRouter(router) {
        this.router = router
    }

    getAddressBySearch = async (search) => {
        return this.doRequest("post", `/utils/search-address`, { "search": search }, false)
    }

    sendContact = async(data) => {
        return this.doRequest("post", '/utils/send-contact', data, false)
    }

    getAddressByZipcode = async (zipcode) => {
        return await axios.get(`https://ws.apicep.com/cep/${zipcode.replace(/\D/g, '')}.json`);
    }

    getIpClient = async () => {
        return await axios.get(`https://api.ipify.org?format=json`);
    }

    getUFlist = () => {
        return UFs
    }

    getCitiesByUf = async (uf) => {
        return await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`);
    }

    check_area_code(value) {
        const area_codes = [
            11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43,
            44, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75,
            77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99
        ]
        return area_codes.indexOf(parseInt(value)) == -1 ? false : true;
    }

    redirectCustomerOnboarding(customerStatus) {

        switch (customerStatus) {
            case 'B':
                this.router.push({
                    name: "offer"
                })
                break;

            case 'O':
                this.router.push({
                     name: "offer"
                })
                //this.router.push({
                    //name: "offerSuccess"
                //})
                break;

            case 'I':
                this.router.push({
                    name: "completeRegister"
                })
                break;

            case 'C':
                this.router.push({
                    name: "contract"
                })
                break;

            case 'D':
                this.router.push({
                    name: "uploadDocuments"
                })
                break;

            case 'F':
                this.router.push({
                    name: "panel-dashboard"
                })
                break;

            default:
                this.router.push({
                    name: "login"
                })
                break;
        }

    }

    getNationalityList() {
        return ["Antiguano", "Argentino", "Bahamense", "Barbadiano, barbadense", "Belizenho", "Boliviano", 
        "Brasileiro", "Chileno", "Colombiano", "Costarriquenho", "Cubano", "Dominicano", "Equatoriano", 
        "Salvadorenho", "Granadino", "Guatemalteco", "Guianês", "Guianense", "Haitiano", "Hondurenho", 
        "Jamaicano", "Mexicano", "Nicaraguense", "Panamenho", "Paraguaio", "Peruano", "Portorriquenho", 
        "Dominicana", "São-cristovense", "São-vicentino", "Santa-lucense", "Surinamês", "Trindadense", 
        "Uruguaio", "Venezuelano", "Alemão", "Austríaco", "Belga", "Croata", "Dinamarquês", "Eslovaco", 
        "Esloveno", "Espanhol", "Francês", "Grego", "Húngaro", "Irlandês", "Italiano", "Noruego", "Holandês", 
        "Polonês", "Português", "Britânico", "Inglês", "Galês", "Escocês", "Romeno", "Russo", "Sérvio", 
        "Sueco", "Suíço", "Turco", "Ucraniano", "Americano", "Canadense", "Angolano", "Moçambicano", 
        "Sul-africano", "Zimbabuense", "Argélia", "Comorense", "Egípcio", "Líbio", "Marroquino", "Ganés", 
        "Queniano", "Ruandês", "Ugandense", "Bechuano", "Marfinense", "Camaronense", "Nigeriano", "Somali", 
        "Australiano", "Neozelandês", "Afegão", "Saudita", "Armeno", "Bangladesh", "Chinês", "Norte-coreano, coreano", 
        "Sul-coreano, coreano", "Indiano", "Indonésio", "Iraquiano", "Iraniano", "Israelita", "Japonês", "Malaio", 
        "Nepalês", "Omanense", "Paquistanês", "Palestino", "Qatarense", "Sírio", "Cingalês", "Tailandês", "Timorense, maubere", 
        "Árabe, emiratense", "Vietnamita", "Iemenita"]
    }

    setRunningInApp = (runningInApp) => {
        LocalStorageService.set('running_in_app', runningInApp)       
    }

    getRunningInApp = (local=true) => {
        return LocalStorageService.get('running_in_app')
    }
}

export default new UtilsService();