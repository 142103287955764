<template>
  <router-view />
  <vue-cookie-accept-decline
    v-if="!runningInApp"
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline"
    @clicked-postpone="cookieClickedPostpone"
    @status="cookieStatus"
    elementId="myPanel1"
    position="bottom"
    ref="myPanel1"
    transitionName="slideFromBottom"
    type="bar"
  >
    <!-- Optional -->
    <template #postponeContent>&times;</template>

    <!-- Optional -->
    <template #message>
      <img src="@/assets/images/minum/logo-cookies.webp" alt="minum" width="100" height="20"/> <br /> <br />
      Usamos cookies para oferecer uma melhor experiência de navegação em nosso site. Você pode gerenciar suas preferências ou aceitar todos os cookies. Para saber mais, consulte a nossa <a href="/politica-de-privacidade"><u>Política de Privacidade</u></a>.
    </template>

    <!-- Optional -->
    <template #declineContent>Recusar</template>

    <!-- Optional -->
    <template #acceptContent>Aceitar</template>
  </vue-cookie-accept-decline>
</template>

<script>
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import WebSiteService from "@/core/services/WebSiteService";
import UtilsService from "@/core/services/UtilsService";
import { initializeComponents } from "@/core/plugins/keenthemes";
import "vue-select/dist/vue-select.css";

export default defineComponent({
  name: "app",
  data() {
    return {
      coockiesInformations : {
        mobile: false,
        platform: null,
        browser: null,
        accept: false,
        ip: null
      }
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
  methods: {
    async cookieRegister(isAccept) {
      const userAgentData = navigator.userAgentData
      this.coockiesInformations.browser = userAgentData.brands[0].brand
      this.coockiesInformations.platform = userAgentData.platform
      this.coockiesInformations.mobile = userAgentData.mobile
      this.coockiesInformations.accept = isAccept
      await UtilsService.getIpClient().then((response)=>{
          console.log(response)
          this.coockiesInformations.ip = response.data.ip;
      })
      console.log(this.coockiesInformations)
      WebSiteService.cookieRegister(this.coockiesInformations)
      .then((response)=>{
          console.log(response)
      })
    },
    cookieClickedAccept() {
      this.cookieRegister(true)
    },  
    cookieClickedDecline() {
      this.cookieRegister(false)
    }
  },
  /* created() {

    let url = window.location.href

    //include de zendeskchat on all pages, exclude partners acess
    if ( url.indexOf("/partner/") == -1 && url.indexOf("/partner-panel") == -1 ) {
      window.onload = function(){
          window.setTimeout(function(){
              var scriptElement=document.createElement('script')
              scriptElement.type = 'text/javascript'
              scriptElement.id = 'ze-snippet'
              scriptElement.src = 'https://static.zdassets.com/ekr/snippet.js?key=29806753-dbd0-4387-a6ef-c60a14bdd26a'
              document.body.appendChild(scriptElement)
          }, 3000);    
      }
    }
      
  }*/
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~quill/dist/quill.snow.css";
//@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";
@import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>
