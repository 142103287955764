
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
    install(Vue) {
      
      Vue.mixin({
          data: function() {
            let isMobile = window.innerWidth < 960
            return {
              checkIsMobile: isMobile,
              checkIsSmallScreen: false,
              trackPadDetectVars: {
                oldTime: 0,
                newTime: 0,
                isTrackPad: null,
                eventCount: 0,
                eventCountStart: null
              },
            }
          },
          computed: {
              isMobile: function () {
                return this.checkIsMobile
              },
              isSmallScreen: function() {
                return this.checkIsSmallScreen
              },
              baseUrl: function() {
                let {protocol, host} = window.location
                return `${protocol}//${host}`
              },
              usingTrackpad: function() {
                return this.trackPadDetectVars.isTrackPad
              },
              runningInApp: function() {
                return typeof this.$route.query['app'] != 'undefined' &&  this.$route.query['app'] == 'true'
              }
          },
          mounted() {
              this.onResize()
              window.addEventListener('resize', this.onResize, { passive: true })
              //document.addEventListener("wheel", this.detectTrackPad, false);
          },
          methods: {
              onResize () {
                  this.checkIsMobile = window.innerWidth < 960
                  this.checkIsSmallScreen = window.innerWidth < 1440
              },
              strToFloat(str) {
                if (!str) {
                  return 0
                }
                return parseFloat(str.replace(/[^\d,.-]/g, '').replaceAll('.','').replaceAll(',','.'))
              },
              slugify(text) {
                return text
                  .toString()                           // Cast to string (optional)
                  .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
                  .toLowerCase()                  // Convert the string to lowercase letters
                  .trim()                                  // Remove whitespace from both sides of a string (optional)
                  .replace(/\s+/g, '-')            // Replace spaces with -
                  .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
                  .replace(/\-\-+/g, '-');        // Replace multiple - with single -
              },
              capitalize(str) {
                return str[0].toUpperCase() + str.slice(1);
              },
              number_format: function(number, decimals, decPoint, thousandsSep) {
                number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
                var n = !isFinite(+number) ? 0 : +number
                var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
                var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
                var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
                var s = ''
    
                var toFixedFix = function (n, prec) {
                    if (('' + n).indexOf('e') === -1) {
                    return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
                    } else {
                    var arr = ('' + n).split('e')
                    var sig = ''
                    if (+arr[1] + prec > 0) {
                        sig = '+'
                    }
                    return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
                    }
                }
    
                // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
                s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
                if (s[0].length > 3) {
                    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
                }
                if ((s[1] || '').length < prec) {
                    s[1] = s[1] || ''
                    s[1] += new Array(prec - s[1].length + 1).join('0')
                }
    
                return s.join(dec)
            }
          }
      })
  
    }
  };
  
  export default GlobalDirectives;
  