class LocalStoraService {

    constructor() {

    }

    set = (key, value) => {
        window.localStorage.setItem(key, JSON.stringify(value))
    }

    get = (key) => {
        return JSON.parse(window.localStorage.getItem(key))
    }

    delete = (key) => {
        window.localStorage.removeItem(key);
    }

    setCustomerData = (data) => {

        let customer_local_data = this.get('customer')

        if (!customer_local_data) {
            customer_local_data = {}
        }

        Object.keys(data).forEach((key) => {
            customer_local_data[key] = data[key]
        })

        this.set('customer', customer_local_data)

    }


    deleteCustomerData = () => {
        this.delete('customer')
    }


    setPartnerData = (data) => {

        let partner_local_data = this.get('partner')

        if (!partner_local_data) {
            partner_local_data = {}
        }

        Object.keys(data).forEach((key) => {
            partner_local_data[key] = data[key]
        })

        this.set('partner', partner_local_data)

    }


    deletePartnerData = () => {
        this.delete('partner')
    }


}

export default new LocalStoraService()