import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueSmoothScroll from 'vue3-smooth-scroll'
import { createGtm } from "vue-gtm";

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import GlobalDirectives from "./globalDirectives";

import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

import { createHead } from "@vueuse/head"
const head = createHead()

import "@/core/plugins/prismjs";
import "bootstrap";

Amplify.configure(awsExports);

const app = createApp(App);

app.use(store);
app.use(router);
//.use(ElementPlus,{});
app.use(VueSmoothScroll, {
    updateHistory: false
});

app.use(GlobalDirectives);

app.use(
    createGtm({
        id: "GTM-WTRMZPG",
    })
);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.use(head);
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

app.mount("#app");
