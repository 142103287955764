
const routes = [
    {
        path: "/panel",
        //importa só o panel sem conteúdo
        component: () => import("@/components/panel-layouts/Panel.vue"),
        meta: { sitemap: { ignoreRoute: true } },
        children: [
            {
                //importa aqui a home do painel
                path: "",
                name: "panel-dashboard",
                component: () => import("@/views/panel/dashboard.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "billing-payments",
                name: "panel-billing-payments",
                component: () => import("@/views/panel/billingAndPayments.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "support-panel",
                name: "support-panel",
                component: () => import("@/views/panel/supportPanel.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "my-account",
                name: "panel-myaccount",
                component: () => import("@/views/panel/myAccount.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "units",
                name: "panel-units",
                component: () => import("@/views/panel/addUnit.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "consumption",
                name: "panel-consuption",
                component: () => import("@/views/panel/consumptionPanel.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            }
        ],
    },

    {
        path: "/partner-panel",
        //importa só o panel sem conteúdo
        component: () => import("@/components/panel-layouts/PartnerPanel.vue"),
        meta: { sitemap: { ignoreRoute: true } },
        children: [
            {
                //importa aqui a home do painel
                path: "",
                name: "partner-panel-dashboard",
                component: () => import("@/views/partner-panel/dashboard.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "indications",
                name: "partner-panel-indications",
                component: () => import("@/views/partner-panel/indications.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "financial",
                name: "partner-panel-financial",
                component: () => import("@/views/partner-panel/financial.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "performance",
                name: "partner-panel-performance",
                component: () => import("@/views/partner-panel/performance.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "support-materials",
                name: "partner-panel-support-materials",
                component: () => import("@/views/partner-panel/support-materials.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "deal/details/:cpf_cnpj/:deal_id",
                name: "deal-deails",
                component: () => import("@/views/partner-panel/deal-details.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
        ],
    },

    {
        // the 404 route, when none of the above matches
        path: "/partner/login",
        name: "partner-login",
        component: () => import("@/views/site/PartnerLogin.vue"),
        meta: { sitemap: { ignoreRoute: true } }
    },

    {
        path: "/",
        component: () => import("@/components/site-layouts/Site.vue"),
        meta: {
            sitemap: {
                lastmod: '2022-09-27',
                priority: 1.0,
                changefreq: 'monthly',
            }
        },
        children: [
            {
                path: "",
                name: "home",
                component: () => {
                    if (window.location.href.indexOf('parceiros.minum.com.br') != -1) {
                        return import("@/views/site/HomePartners.vue")
                    }
                    if (window.location.href.indexOf('instaladores.minum.com.br') != -1) {
                        return import("@/views/site/HomeInstallers.vue")
                    } 
                    return import("@/views/site/Home.vue")
                },
                
            },
            {
                path: "/parceiros",
                name: "partners",
                component: () => import("@/views/site/HomePartners.vue"),
            },
            {
                path: "/instaladores",
                name: "installers",
                component: () => import("@/views/site/HomeInstallers.vue"),
            },
            {
                path: "/lp-simulator",
                name: "lp-simulator",
                component: () => import("@/views/site/HomeLpSimulator.vue")
            },
            {
                path: "/lp-simulator-2",
                name: "lp-simulator-2",
                component: () => import("@/views/site/HomeLpSimulator2.vue")
            },
            {
                path: "/simule-seu-plano",
                name: "simulator",
                component: () => import("@/views/site/Simulator.vue"),
                
            },
            {
                path: "/sobre-o-plano",
                name: "about-plan",
                component: () => import("@/views/site/AboutPlan.vue"),
                
            },
            {
                path: "/sobre-nos",
                name: "about-us",
                component: () => import("@/views/site/AboutUs.vue"),
                
            },
            {
                path: "/faq",
                name: "faq",
                component: () => import("@/views/site/Faq.vue"),
                
            },
            {
                path: "/politica-de-privacidade",
                name: "privacy-policy",
                component: () => import("@/views/site/PrivacyPolicy.vue"),
                
            },
            {
                path: "/termos-e-condicoes",
                name: "terms-conditions",
                component: () => import("@/views/site/TermsConditions.vue"),
                
            },
            {
                path: "/verify-email/:encoded_email/:token",
                name: "verify-email",
                component: () => import("@/views/site/VerifyEmail.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "/contract-assign/:hash/",
                name: "contract-assing",
                component: () => import("@/views/site/ContractAssign.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "/unsubscribe/:email_hash",
                name: "email-unsubscribe",
                component: () => import("@/views/site/Unsubscribe.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "/revert-email/:encoded_email_otp",
                name: "revert-email",
                component: () => import("@/views/site/RevertEmail.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },

            {
                path: "/blog",
                name: "blog-home",
                component: () => import("@/views/site/BlogHome.vue"),
                meta: { sitemap: { ignoreRoute: false } }
            },


            {
                path: "/blog/author/:author",
                name: "blog-author-list",
                component: () => import("@/views/site/BlogList.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },

            {
                path: "/blog/:category",
                name: "blog-category-list",
                component: () => import("@/views/site/BlogList.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },

            {
                path: "/blog/:category/:slug",
                name: "blog-details",
                component: () => import("@/views/site/BlogDetails.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
        ],
    },
    {
        path: "/onboarding/register",
        name: "register",
        component: () => import("@/views/site/Register.vue"),
        meta: { sitemap: { ignoreRoute: true } },
    },
    {
        path: "/onboarding",
        component: () => import("@/components/site-layouts/Onboarding.vue"),
        meta: { sitemap: { ignoreRoute: true } },
        children: [
            {
                path: "offer",
                name: "offer",
                component: () => import("@/views/site/Offer.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "offer-success",
                name: "offerSuccess",
                component: () => import("@/views/site/OfferSuccess.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "complete-register",
                name: "completeRegister",
                component: () => import("@/views/site/CompleteRegister.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "contract",
                name: "contract",
                component: () => import("@/views/site/Contract.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "upload-documents",
                name: "uploadDocuments",
                component: () => import("@/views/site/UploadDoc.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            },
            {
                path: "success",
                name: "onboarding-success",
                component: () => import("@/views/site/OnboardingSuccess.vue"),
                meta: { sitemap: { ignoreRoute: true } }
            }
        ]
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        // the 404 route, when none of the above matches
        path: "/login",
        name: "login",
        component: () => import("@/views/site/Login.vue"),
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: { sitemap: { ignoreRoute: true } }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
        meta: { sitemap: { ignoreRoute: true } }
    },
];

module.exports = { routes }