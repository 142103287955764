import LocalStorageService from "./LocalStorageService";
export default function authHeader(use_auth=true) {
    let user = LocalStorageService.get('customer')

    if (!user || Object.keys(user).length == 0) {
      user = LocalStorageService.get('partner')
    }

    if (use_auth  && user && user.access) {
      // for Node.js Express back-end
      return { 'Authorization': user.access, "Content-Type": "application/json" };
    } else {
      return {"Content-Type": "application/json"};
    }
  }
