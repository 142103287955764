/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "EnergyWebSiteApi",
            "endpoint": "https://qg47u4szwi.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
