import Amplify, { API } from 'aws-amplify'
import LocalStorageService from "./LocalStorageService";
import authHeader from './AuthHeader'
//import history from "@history.js";
//import jwtAuthService from "./jwtAuthService";

class MainService {

    api_name = null
    api_path = null
    api_request = {}

    constructor() {
        
    }

    doRequest = async (method, action, data = {}, use_auth=true) => {

        this.api_request = { headers: authHeader(use_auth)}

        var request = null

        switch (method) {
            case "get":
                request = this.get(action)
                break;

            case "post":
                request = this.post(action, data)
                break;

            case "del":
                request = this.delete(action)
                break;
        
            default:
                request = this.post(action, data)
                break;
        }

        let retry_request = {
            "method": method,
            "action": action,
            "data": data,
            "use_auth": use_auth
        }
        
        this.checkLogged(request, retry_request)
        
        return request

    }

    get = async (action) => {
        action = action
        let request = this.api_request
        return API.get(this.api_name, `${(this.api_path ? `/${this.api_path}/` : ``)}${action}`, request)
    }

    delete = async(action) => {
        action = action
        let request = this.api_request
        request['body'] = {}
        return API.del(this.api_name, `${(this.api_path ? `/${this.api_path}/` : ``)}${action}`, request)
    }

    post = async (action, data, ) => {
        action = action
        let request = this.api_request
        request['body'] = data
        return API.post(this.api_name, `${(this.api_path ? `/${this.api_path}/` : ``)}${action}`, request)
    }

    refreshAccessToken = async() => {

        let user = LocalStorageService.get('customer')
        if (!user) {
            user = LocalStorageService.get('partner')
        }

        console.log("resfresL usee: ", user)

        let request = {}
        request['body'] = {
            'refresh': user['refresh']
        }

        return API.post(this.api_name, '/customer/token-refresh', request)
    }

    checkLogged = async (request, retry_request=null) => {
        request.catch(
            async error => {

                let status_code = error.response.status
                let response = error.response.data

                let retry_request_again = false

                if ( ( typeof response['code'] != 'undefined' && response['code'] == 'token_not_valid' ) ||
                    (typeof response['detail'] != 'undefined' && response['detail'] == 'As credenciais de autenticação não foram fornecidas.'  ) ) {
                    
                    await this.refreshAccessToken()
                    .then(async refresh => {

                        let user = LocalStorageService.get('customer')
                        LocalStorageService.setCustomerData({'access': refresh.access})
                        if (!user) {
                            user = LocalStorageService.get('partner')
                            LocalStorageService.setPartnerData({'access': refresh.access})
                        }

                        retry_request_again = true
                    })
                    .catch((e)=>{
                        LocalStorageService.deleteCustomerData()
                        LocalStorageService.deletePartnerData()
                        //LocalStorageService.deleteCustomerData()
                    })

                    // if (retry_request_again) {
                    //    window.location.reload()
                    // }

                   window.location.reload()

                }
                
            }
        )

    }
}

export default MainService;