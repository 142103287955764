enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_SHOW_LOADING = "setShowLoading",
  SET_ONBOARDING_TITLE = "setOnboardingTitle",
  SET_ONBOARDING_NEXT = "setOnboardingNext",
  SET_ONBOARDING_BACK = "setOnboardingBack",
  SET_ONBOARDING_NEXT_ENABLED = "setOnboardingNextEnabled",
  SET_ONBOARDING_BACK_ENABLED = "setOnboardingBackEnabled",
  SET_ONBOARDING_STEP = "setOnboardingStep",
  SET_ACCEPT_OFFER = "setAcceptOffer",
  SET_SIGNED_DOCUMENT = "setSignedDocument"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_SHOW_LOADING_MUTATION = "setShowLoadingMutation",
  SET_ONBOARDING_TITLE_MUTATION = "setOnboardingTitleMutation",
  SET_ONBOARDING_NEXT_MUTATION = "setOnboardingNextMutation",
  SET_ONBOARDING_BACK_MUTATION = "setOnboardingBackMutation",
  SET_ONBOARDING_NEXT_ENABLED_MUTATION = "setOnboardingNextEnabledMutation",
  SET_ONBOARDING_BACK_ENABLED_MUTATION = "setOnboardingBackEnabledMutation",
  SET_ONBOARDING_STEP_MUTATION = "setOnboardingStepMutation",
  SET_ACCEPT_OFFER_MUTATION = "setAcceptOfferMutation",
  SET_SIGNED_DOCUMENT_MUTATION = "setSignedDocumentMutation"
}

export { Actions, Mutations };
